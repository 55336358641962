// Modal
var modal = document.getElementById("modal-servicios");
var modalCerrar = document.getElementsByClassName("modal-body-header-x")[0];
// Botones
var chef = document.getElementById("servicio-chef");
var carrito = document.getElementById("servicio-carrito");
// Campos formulario
var formularioChef = document.getElementById("formulario-chef");
var formularioCarrito = document.getElementById("formulario-carrito");

var formularioChef2 = document.getElementById("formulario-chef2");
var formularioCarrito2 = document.getElementById("formulario-carrito2");
// Send form
var botonEnviar = document.getElementById("boton_enviar");

chef.addEventListener("click",function(e){

    modal.classList.add("open-modal");
    formularioChef.classList.remove("ocultar");
    formularioCarrito.classList.add("ocultar");
    formularioChef2.classList.remove("ocultar");
    formularioCarrito2.classList.add("ocultar");

});

carrito.addEventListener("click",function(e){

    modal.classList.add("open-modal");
    formularioCarrito.classList.remove("ocultar");
    formularioChef.classList.add("ocultar");
    formularioCarrito2.classList.remove("ocultar");
    formularioChef2.classList.add("ocultar");

});

modalCerrar.addEventListener("click",function(e){

    modal.classList.remove("open-modal");

});

botonEnviar.addEventListener("click",function(e){

    // setTimeout(function(){ modal.classList.remove("open-modal"); }, 10000);

});